import Vue from 'vue';
import VueRouter from 'vue-router';

// 导入你的组件
import Home from '../views/HomeComponent.vue';
import Login from '../views/LoginComponent.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
  // 其他路由配置
];

const router = new VueRouter({
  mode: 'history', // 使用 HTML5 History 模式
  base: process.env.BASE_URL,
  routes
});

export default router;