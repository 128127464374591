<template>
    <div>
      <div class="sticky-header">
        <button @click="nextVocabulary">Next</button>
        <button @click="killVocabulary">Kill</button>
        <button @click="summary">Summary</button>
        <div class="container">
          <input type="text" v-model="query" @keyup.enter="search"
            placeholder="search a word or ask a question(Plus user only)">
        </div>
  
        <div id="audio">
          <h2>{{ word }}</h2>
          <audio :src="audioUrl" controls></audio>
        </div>
      </div>
  
      <div class="content">
        <div v-html="previewHtml" id="preview"></div>
      </div>
    </div>
  </template>
  
  <script>
  import { nextVocabulary, killVocabulary, summary, searchVocabulary } from '@/services/api';
  import marked from 'marked';
  
  export default {
    name: 'HomeComponent',
    data() {
      return {
        search_history: [],
        title: 'Magic Notebook',
        word: '',
        previewHtml: '',
        audioUrl: '',
        query: '',
      };
    },
    methods: {
      search() {
        if (this.query.trim()) {
          const keyword = this.query;
          searchVocabulary(keyword).then(data => {
            if (data.success) {
              this.search_history.push(keyword);
              this.fillData(data);
            }
          }).catch(error => {
            console.error('Error searching vocabulary:', error);
          });
        } else {
          alert('请输入查询内容');
        }
      },
      summary() {
        summary().then(data => {
          if (data.success) {
            const { title, content } = data.result;
            this.word = title;
            this.previewHtml = content;
            document.title = title;
            this.scrollToTop();
          }
        }).catch(error => {
          console.error('Error fetching summary:', error);
        });
      },
      killVocabulary() {
        const currentWord = this.word;
        console.log("currentWord:", currentWord);
        killVocabulary(currentWord).then(data => {
          if (data.success) {
            this.nextVocabulary();
          }
        }).catch(error => {
          console.error('Error killing vocabulary:', error);
        });
      },
      nextVocabulary() {
        nextVocabulary().then(data => {
          if (data.success) {
            this.fillData(data);
          }
        }).catch(error => {
          console.error('Error fetching next vocabulary:', error);
        });
      },
      scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      },
      fillData(data) {
        const { title, content } = data.result;
        const markdown = JSON.parse(content).content;
        const html = marked(markdown);
        this.audioUrl = `http://dict.youdao.com/dictvoice?type=0&audio=${title}`;
        this.word = title;
        this.previewHtml = html;
        document.title = title;
        this.scrollToTop();
        if (this.query !== title) {
          this.query = "";
        }
      }
    },
    mounted() {
      this.nextVocabulary();
    }
  };
  </script>
  
  <style scoped>
  /* 你的样式代码 */
  </style>