<template>
  <div class="login-form">
    <h2>Login</h2>
    <form @submit.prevent="handleLogin">
      <div>
        <label for="username">Username:</label>
        <input type="text" v-model="username" id="username" required />
      </div>
      <div>
        <label for="password">Password:</label>
        <input type="password" v-model="password" id="password" required />
      </div>
      <button type="submit">Login</button>
    </form>
  </div>
</template>

<script>
import { login } from '@/services/api';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      username: '',
      password: ''
    };
  },
  methods: {
    ...mapActions(['setToken']), // 映射 setToken 方法
    async handleLogin() {
      try {
        const data = await login(this.username, this.password);
        if (data && data.result) {
          // 存储 JWT 到 Vuex 和本地存储
          this.setToken(data.result);
          this.$router.push('/');
        } else {
          alert('Invalid credentials');
        }
      } catch (error) {
        console.log('Login failed:', error);
        // alert('Login failed. Please check your credentials.');
      }
    }
  }
};
</script>

<style scoped>
/* 你的样式代码 */
</style>