import Vue from 'vue';
import Vuex from 'vuex';

// 安装 Vuex 插件
Vue.use(Vuex);

// 创建 Vuex store
export default new Vuex.Store({
  state: {
    // 状态对象
    count: 0,
    token: localStorage.getItem('token') || null
  },
  mutations: {
    // 修改状态的方法
    increment(state) {
      state.count++;
    },
    SET_TOKEN(state, token) {
      state.token = token;
      localStorage.setItem('token', token);
    }
  },
  actions: {
    // 异步操作
    incrementAsync({ commit }) {
      setTimeout(() => {
        commit('increment');
      }, 1000);
    },
    setToken({ commit }, token) {
      commit('SET_TOKEN', token);
    }
  },
  getters: {
    // 计算属性
    getCount: state => state.count,
    getToken: state => state.token
  }
});



