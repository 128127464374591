<template>
    <div id="app">
      <header>
        <!-- 你的头部内容 -->
      </header>
      <router-view></router-view> <!-- 这里是路由视图 -->
      <footer>
        <!-- 你的底部内容 -->
      </footer>
    </div>
  </template>
  
  <script>
  export default {
    name: 'App'
  };
  </script>
  
  <style>
  /* 你的样式代码 */
  </style>