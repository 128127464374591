import axios from 'axios';

// 创建一个 Axios 实例
const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL, // 使用环境变量
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': 'Bearer Basic c3dvcmQ6c3dvcmRfc2VjcmV0',
    'token':'',
  }
});

// 添加请求拦截器
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.token = `${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  (response) => {
    return response.data; // 直接返回响应的数据部分
  },
  (error) => {
    console.log("request error = "+ error)
    if (error.response && error.response.status === 401) {
      // 处理未授权的情况
      localStorage.removeItem('token');
      if (window.location.pathname !== '/login') {
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

// 封装具体的 API 请求
export const nextVocabulary = () => {
  return instance.get('/v1/next/vocabulary');
};

export const killVocabulary = (word) => {
  return instance.delete(`/vocabulary/kill/${word}`);
};

export const summary = () => {
  return instance.get('/vocabulary/summary');
};

export const searchVocabulary = (keyword) => {
  return instance.get(`/vocabulary/search/${keyword}`);
};


export const login = (username, password) => {
    return instance.post(`/login`,{username,password});
  };